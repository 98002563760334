<template lang="pug">
ejs-treemap.w-100(:ref="name" v-if="dataLoaded" :key="key" style='display:block' :margin="{bottom:0, top:0, left:0, right:0}" align="center" :rangeColorValuePath='rangeColorValuePath' format='n' useGroupingSeparator='useGroupingSeparator' :dataSource='data' :leafItemSettings='leafItemSettings' :tooltipSettings='tooltipSettings' :weightValuePath='weightValuePath' :palette="palette" tooltipMappingName='label')
</template>

<script>
import { TreeMapComponent } from "@syncfusion/ej2-vue-treemap";
import { TreeMapTooltip } from "@syncfusion/ej2-vue-treemap";

export default {
    props:{
        data:{
            type:Array,
            default: () => []
        },
        viewType:{
            type:String,
            default:"amount"
        },
        viewMode:{
            type:String,
            default:"compare"
        }
    },
    data() {
        return {
            title: "Répartition par affaires",
            name: "repartitionByAffairs",
            dataLoaded:false,
            key:0,

            weightValuePath: 'totalCA',
            tooltipSettings: { 
                visible: true,
                format: 'Affair : ${affairLabel}<br> <br>Client : ${companyLabel}<br> <br>CA : ${displayText_totalCA}',
            },
            rangeColorValuePath: 'totalCA',
            palette:['#0C3571','#3F5899','#6A7EC3','#94A6EF','#BFD0FF'],
            leafItemSettings: {
                labelPath: 'affairLabel',
                labelFormat: '${affairLabel}',
                labelStyle: {
                    color: '#ffffff',
                },
                labelPosition:'Center',
                border: {
                    color: '#ffffff',
                    width: 0.5
                },
            },
        }
    },
    watch : {
        data: function (newVal, oldVal) {
            if(newVal !== oldVal) {
                this.dataLoaded = false
                this.$nextTick(()=>{
                    this.dataLoaded = true
                })
            }
        },
    },
    async mounted(){
        this.dataLoaded=true
    },
    provide: {
        treemap:[TreeMapTooltip]
    },
    components: {
        'ejs-treemap': TreeMapComponent
    }
};
</script>