<template lang="pug">
ejs-chart#globalChartBilling(:ref="name" height="100%" :key="key" theme='bootstrap5' align='center'  :primaryXAxis='primaryXAxis' :primaryYAxis='primaryYAxis' :chartArea='chartArea' :width='width' :legendSettings='legendSettings' :tooltip='tooltip' style="display:block; margin-top:-1rem" @legendClick="onLegendClick")
    e-series-collection
        e-series(:dataSource='data' v-for="(serie, index) of series"  :key="index" :visible="serie.visible" v-if="viewType == serie.mode" :fill="serie.color" :type='serie.type' xName='date' :yName='serie.yName' :name='serie.name'  :cornerRadius="cornerRadius" :groupName='serie.groupName' columnWidth='.5' opacity='1' columnSpacing='0' :tooltipMappingName="serie.yName+'DisplayText'" :border="{color: serie.color, width: 2 }")
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import {
  LineSeries,
  StackingColumnSeries,
  Tooltip,
  ColumnSeries,
  Category,
  Legend,
  DataLabel,
  Highlight,
  ChartAnnotation,
} from "@syncfusion/ej2-vue-charts";

export default {
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    viewType: {
      type: String,
      default: "amount",
    },
    viewMode: {
      type: String,
      default: "compare",
    },
  },
  data() {
    return {
      title: "Synthèse analytique de la facturation",
      name: "globalChartBilling",
      dataLoaded: false,
      key: 1,
      seriesDefault: [
        {
          color: "#00A7A6",
          type: "Column",
          yName: "totalQuotesSent",
          name: "Devis envoyés",
          groupName: "quotesSent",
          mode: "count",
          visible: true,
        },
        {
          color: "#00A7A6",
          type: "Column",
          yName: "amountQuotesSent",
          name: "Devis envoyés",
          groupName: "quotesSent",
          mode: "amount",
          visible: true,
        },

        {
          color: "#00A7A6",
          type: "Column",
          yName: "totalQuotesSigned",
          name: "Devis signés",
          groupName: "quotesSigned",
          mode: "count",
          visible: true,
        },
        {
          color: "#00A7A6",
          type: "Column",
          yName: "amountQuotesSigned",
          name: "Devis signés",
          groupName: "quotesSigned",
          mode: "amount",
          visible: true,
        },

        {
          color: "#0C3571",
          type: "Column",
          yName: "totalOrderFormsCustomersSent",
          name: "Commandes validées",
          groupName: "orderFormsCustomersSent",
          mode: "count",
          visible: true,
        },
        {
          color: "#0C3571",
          type: "Column",
          yName: "amountOrderFormsCustomersSent",
          name: "Commandes validées",
          groupName: "orderFormsCustomersSent",
          mode: "amount",
          visible: true,
        },

        {
          color: "#0C3571",
          type: "Column",
          yName: "totalInvoicesSent",
          name: "Chiffre d'affaires",
          groupName: "invoicesSent",
          mode: "count",
          visible: true,
        },
        {
          color: "#0C3571",
          type: "Column",
          yName: "amountInvoicesSent",
          name: "Chiffre d'affaires",
          groupName: "invoicesSent",
          mode: "amount",
          visible: true,
        },

        {
          color: "#28a746",
          type: "Column",
          yName: "totalPayments",
          name: "Encaissements",
          groupName: "payments",
          mode: "count",
          visible: true,
        },
        {
          color: "#28a746",
          type: "Column",
          yName: "amountPayments",
          name: "Encaissements",
          groupName: "payments",
          mode: "amount",
          visible: true,
        },
      ],
      series: [],

      //Initializing Primary X Axis
      primaryXAxis: {
        title: "",
        labelIntersectAction: "Rotate45",
        valueType: "Category",
        majorTickLines: { width: 0 },
      },

      primaryYAxis: {
        // title: '',
        // lineStyle: { width: 0 },
        // majorTickLines: { width: 0 },
        // labelFormat: '{value} €',
        // lineStyle: { width: 0 },
        // majorTickLines: { width: 0 },
        // majorGridLines: { color: '#eaeaea', width: 1 }
      },
      chartArea: {
        border: {
          width: 0,
        },
      },
      circleMarkerForecast: {
        visible: true,
        height: 5,
        width: 5,
        shape: "Circle",
        fill: "#ffffff",
        border: { width: 2, color: "#ff9f43" },
      },
      circleMarkerCounted: {
        visible: true,
        height: 5,
        width: 5,
        shape: "Circle",
        fill: "#ffffff",
        border: { width: 2, color: "#0c3571" },
      },
      cornerRadius: {
        bottomLeft: 0,
        bottomRight: 0,
        topLeft: 4,
        topRight: 4,
      },
      markerForecastOutput: {
        visible: true,
        shape: "Rectangle",
        isFilled: true,
        border: { width: 2, color: "#ffffff" },
        height: 3,
        width: 44,
        label: "test",
        dataLabel: {
          alignment: "center",
          border: { width: 2, color: "#0c3571" },
          fill: "#0c3571",
          visible: true,
        },
      },
      markerForecastInput: {
        visible: true,
        shape: "Rectangle",
        isFilled: true,
        border: { width: 2, color: "#ffffff" },
        height: 3,
        width: 44,
      },
      border: {
        color: "black",
        width: 2,
      },
      width: "100%",
      legendSettings: {
        visible: true,
        enableHighlight: false,
        position: "Top",
        alignment: "Far",
      },
      tooltip: {
        enable: true,
        header: "${point.x}",
        format: "${series.name} : <b>${point.tooltip}</b>",
      },
      palettes: [
        "#dc3545",
        "#dc3545",
        "#28a745",
        "#28a745",
        "#0c3571",
        "#ff9f43",
      ],
    };
  },
  computed: {
    ...mapGetters(["userParametersList"]),
  },
  watch: {
    data: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataLoaded = false;
        this.$nextTick(() => {
          this.dataLoaded = true;
        });
      }
    },
  },
  async created() {
    if (
      this.userParametersList.find((el) => el.key == "globalChartBilling") &&
      this.userParametersList.find((el) => el.key == "globalChartBilling") &&
      JSON.parse(
        this.userParametersList.find((el) => el.key == "globalChartBilling")
          .value
      ) &&
      JSON.parse(
        this.userParametersList.find((el) => el.key == "globalChartBilling")
          .value
      ).series
    ) {
      this.series = JSON.parse(
        this.userParametersList.find((el) => el.key == "globalChartBilling")
          .value
      ).series;
    } else {
      this.series = JSON.parse(JSON.stringify(this.seriesDefault));
      this.createOrUpdateUserParameter({
        label:
          "Paramètres du graphique des statistiques de facturation par période",
        key: "globalChartBilling",
        value: JSON.stringify({ series: this.series }),
      });
    }
  },
  async mounted() {
    this.dataLoaded = true;
  },
  methods: {
    ...mapActions(["createOrUpdateUserParameter"]),
    onLegendClick(args) {
      this.series[
        this.series.findIndex(
          (serie) => serie.yName == args.series.properties.yName
        )
      ].visible = !args.series.properties.visible;
      this.createOrUpdateUserParameter({
        label:
          "Paramètres du graphique des statistiques de facturation par période",
        key: "globalChartBilling",
        value: JSON.stringify({ series: this.series }),
      });
    },
  },
  provide: {
    chart: [
      StackingColumnSeries,
      LineSeries,
      Category,
      ColumnSeries,
      Legend,
      Tooltip,
      Highlight,
      DataLabel,
      ChartAnnotation,
    ],
  },
};
</script>
