<template lang="pug">
  sync-grid#overviewgrid-dashboard(ref="overviewgrid" :uid="uid" :gridData="data" :key="key" :toolbar="toolbar" :name="name" v-if="dataLoaded" :headerData="headerData" :frozenColumns="frozenColumns" :allowGrouping="allowGrouping" :allowKeyboard="allowKeyboard" :tagguerButton="tagguerButton" @actionBegin="actionBegin" :editSettings="editSettings" style="border: 0px !important;" :saveGridStateProps="false")
</template>

<script>
import { mapGetters } from "vuex";
import {
  formatCurrency,
  formatNumber,
} from "@/types/api-orisis/library/FormatOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import CellRendererColorCurrency from "@/components/global/ag-grid/CellRendererColorCurrency.vue";

export default {
  components: {
    SyncGrid,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    dateRange: {
      type: Object,
      default: {
        startDate: new Date(
          dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
        ).toISOString(),
        endDate: new Date(
          dayjs().endOf("month").format("YYYY-MM-DD")
        ).toISOString(),
      },
    },
    viewType: {
      type: String,
      default: "amount",
    },
    viewMode: {
      type: String,
      default: "compare",
    },
    viewSlot: {
      type: String,
      default: "global",
    },
  },
  data() {
    return {
      title: "Suivi par affaires",
      name: "supervisionByAffairs",
      uid: "globalGridForecast",
      dataLoaded: false,
      key: 0,

      tagguerButton: false,
      allowKeyboard: false,
      allowGrouping: false,
      frozenColumns: 1,
      selectionSettings: {
        type: "Multiple",
        enableSimpleMultiRowSelection: false,
      },
      editSettings: {
        allowEditing: false,
        allowAdding: false,
        allowDeleting: false,
      },
      headerLoaded: false,
      toolbar: [
        { text: "Search", align: "Right" },
        {
          text: "Imprimer",
          prefixIcon: "e-print",
          id: "overviewgrid-dashboard_print",
          align: "Right",
        },
        {
          text: "Exporter en PDF",
          prefixIcon: "e-pdfexport",
          id: "overviewgrid-dashboard_pdfexport",
          align: "Right",
        },
        {
          text: "Exporter en Excel",
          prefixIcon: "e-excelexport",
          id: "overviewgrid-dashboard_excelexport",
          align: "Right",
        },
        {
          text: "Exporter en CSV",
          prefixIcon: "e-csvexport",
          id: "overviewgrid-dashboard_csvexport",
          align: "Right",
        },
        "ColumnChooser",
      ],

      colorCurrencyTemplate() {
        return {
          template: CellRendererColorCurrency,
        };
      },
    };
  },
  watch: {
    data: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataLoaded = false;
        this.$nextTick(() => {
          this.dataLoaded = true;
        });
      }
    },
    viewMode: function (newVal, oldVal) {
      this.dataLoaded = false;
      this.headerData.forEach((cell) => {
        if (newVal == "counted") {
          if (cell.mode && cell.mode == "counted") {
            cell.visible = true;
          } else if (cell.mode && cell.mode !== "counted") {
            cell.visible = false;
          }
        } else if (newVal == "forecast") {
          if (cell.mode && cell.mode == "forecast") {
            cell.visible = true;
          } else if (cell.mode && cell.mode !== "forecast") {
            cell.visible = false;
          }
        } else {
          cell.visible = true;
        }
        if (cell.columns && cell.columns.length > 0) {
          cell.columns.forEach((subCell) => {
            if (newVal == "counted") {
              if (subCell.mode && subCell.mode == "counted") {
                subCell.visible = true;
              } else if (subCell.mode && subCell.mode !== "counted") {
                subCell.visible = false;
              }
            } else if (newVal == "forecast") {
              if (subCell.mode && subCell.mode == "forecast") {
                subCell.visible = true;
              } else if (subCell.mode && subCell.mode !== "forecast") {
                subCell.visible = false;
              }
            } else {
              subCell.visible = true;
            }
          });
        }
      });
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
      this.$nextTick(() => {
        this.dataLoaded = true;
      });
    },
  },
  async mounted() {
    this.setHeaderData();
    this.dataLoaded = true;
  },
  computed: {
    ...mapGetters([
      "analyticsSettingsList",
      "affairsList",
      "moduleParametersList",
    ]),
    displayTotalCATtc() {
      return this.moduleParametersList.find(
        (el) => el.key == "displayTotalCATtcGlobalGridForecast"
      )
        ? JSON.parse(
            this.moduleParametersList.find(
              (el) => el.key == "displayTotalCATtcGlobalGridForecast"
            ).value
          )
        : false;
    },
  },
  methods: {
    formatNumber,
    setHeaderData() {
      // On génère les colonnes des mois
      let dateDebut = new Date(this.dateRange.startDate);
      let dateFin = new Date(this.dateRange.endDate);
      let dateActuelle = new Date(dateDebut);

      let columnsExpensesByAnalytalCodes = [];
      this.analyticsSettingsList.forEach((analytic) => {
        let cellExpense = {
          type: "number",
          field: "expenseByAnalytalCodes_" + analytic.id,
          headerText: analytic.label,
          width: 140,
          minWidth: 140,
          allowEdit: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          textAlign: "right",
          customAttributes:
            analytic.color && analytic.color.length > 2
              ? { class: ["bg_" + analytic.color.substring(1)] }
              : null,
          // template: this.colorCurrencyTemplate,
          valueAccessor: function (field, data, column) {
            return formatCurrency(data[field]);
          },
        };
        columnsExpensesByAnalytalCodes.push(cellExpense);
      });

      let columnsDetailMonths = [];

      while (dateActuelle <= dateFin) {
        let dateStr = dayjs(dateActuelle).startOf("month").format("MM_YYYY");
        let dateFormated = dayjs(dateActuelle)
          .startOf("month")
          .format("MMM YY");
        let columnsMonth = {
          headerText:
            dateFormated.charAt(0).toUpperCase() + dateFormated.slice(1),
          customCss: "text-primary",
          customAttributes: { class: "text-primary" },
          columns: [],
        };
        let cellCAMonth = {
          type: "number",
          field: dateStr + "_totalCA",
          headerText: "CA (HT)",
          width: 140,
          minWidth: 140,
          allowEdit: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          textAlign: "right",
          template: this.colorCurrencyTemplate,
          valueAccessor: function (field, data, column) {
            return formatCurrency(data[field]);
          },
        };
        columnsMonth.columns.push(cellCAMonth);
        let cellCATtcMonth = {
          type: "number",
          field: dateStr + "_totalCATtc",
          headerText: "CA (TTC)",
          width: 140,
          minWidth: 140,
          allowEdit: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          textAlign: "right",
          template: this.colorCurrencyTemplate,
          format: "C2",
        };
        if (this.displayTotalCATtc) columnsMonth.columns.push(cellCATtcMonth);
        let cellPaymentsMonth = {
          type: "number",
          field: dateStr + "_totalPayments",
          headerText: "Règlements (TTC)",
          width: 140,
          minWidth: 140,
          allowEdit: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          textAlign: "center",
          valueAccessor: function (field, data, column) {
            return data[field] ? data[field] : "--";
          },
          format: "C2",
        };
        columnsMonth.columns.push(cellPaymentsMonth);
        let cellExpenseMonth = {
          type: "number",
          field: dateStr + "_totalOfExpenses",
          headerText: "Charges",
          width: 140,
          minWidth: 140,
          allowEdit: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          textAlign: "right",
          template: this.colorCurrencyTemplate,
          format: "C2",
          customAttributes: { class: "e-column-border-right-light" },
        };
        columnsMonth.columns.push(cellExpenseMonth);

        columnsDetailMonths.push(columnsMonth);

        if (dateActuelle.getMonth() === 11) {
          dateActuelle.setFullYear(dateActuelle.getFullYear() + 1);
          dateActuelle.setMonth(0);
        } else {
          dateActuelle.setMonth(dateActuelle.getMonth() + 1);
        }
      }
      // this.frozenColumns = null

      this.headerData = [
        {
          type: "string",
          field: "affairLabel",
          headerText: "Affaire",
          direction: "Ascending",
          width: 300,
          minWidth: 100,
          allowEdit: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          textAlign: "left",
        },
        {
          mode: "counted",
          visible:
            this.viewMode == "compare" || this.viewMode == "counted"
              ? true
              : false,
          headerText: "Réél",
          customCss: "text-primary",
          customAttributes: { class: "e-column-border-right-light" },
          columns: [
            {
              mode: "counted",
              visible:
                this.viewMode == "compare" || this.viewMode == "counted"
                  ? true
                  : false,
              type: "number",
              field: "totalCA",
              label: "CA réalisé (HT)",
              headerText: "CA réalisé (HT)",
              width: 140,
              minWidth: 140,
              allowEdit: true,
              allowFiltering: true,
              allowSorting: true,
              showInColumnChooser: true,
              textAlign: "right",
              format: "C2",
              customAttributes: {
                class: [
                  "bg-light-grey",
                  "text-primary",
                  "font-weight-bolder",
                  "e-column-border-right-light",
                ],
              },
            },
            {
              mode: "counted",
              visible:
                (this.viewMode == "compare" || this.viewMode == "counted") &&
                this.displayTotalCATtc
                  ? true
                  : false,
              type: "number",
              field: "totalCATtc",
              label: "CA réalisé (TTC)",
              headerText: "CA réalisé (TTC)",
              width: 140,
              minWidth: 140,
              allowEdit: true,
              allowFiltering: true,
              allowSorting: true,
              showInColumnChooser: true,
              textAlign: "right",
              format: "C2",
              customAttributes: {
                class: [
                  "bg-light-grey",
                  "text-primary",
                  "font-weight-bolder",
                  "e-column-border-right-light",
                ],
              },
            },
            {
              mode: "counted",
              visible:
                this.viewMode == "compare" || this.viewMode == "counted"
                  ? true
                  : false,
              type: "number",
              field: "totalPayments",
              label: "Règlements",
              headerText: "Règlements (TTC)",
              width: 140,
              minWidth: 140,
              allowEdit: true,
              allowFiltering: true,
              allowSorting: true,
              showInColumnChooser: true,
              textAlign: "right",
              format: "C2",
            },
            {
              headerText: "Charges par analytique",
              customCss: "text-primary",
              customAttributes: {
                class: [
                  "text-primary",
                  "e-column-border-left-light",
                  "font-weight-bolder",
                ],
              },
              columns: columnsExpensesByAnalytalCodes,
            },
            {
              mode: "counted",
              visible:
                this.viewMode == "compare" || this.viewMode == "counted"
                  ? true
                  : false,
              type: "number",
              field: "totalOfExpenses",
              label: "Total Charges réalisées",
              headerText: "Total Charges réalisées",
              width: 140,
              minWidth: 140,
              allowEdit: true,
              allowFiltering: true,
              allowSorting: true,
              showInColumnChooser: true,
              textAlign: "right",
              template: this.colorCurrencyTemplate,
              valueAccessor: function (field, data, column) {
                return data[field] > 0 ? data[field] : "--";
              },
              format: "C2",
            },

            {
              mode: "counted",
              visible:
                this.viewMode == "compare" || this.viewMode == "counted"
                  ? true
                  : false,
              type: "number",
              field: "marginCostingPercent",
              label: "Marge brute",
              headerText: "% Marge brute",
              width: 90,
              minWidth: 90,
              allowEdit: true,
              allowFiltering: true,
              allowSorting: true,
              showInColumnChooser: true,
              textAlign: "center",
              showFooterSum: true,
              defaultValue: 0,
              valueAccessor: function (field, data, column) {
                if (data["totalCA"] > 0) {
                  return formatNumber(
                    (data["totalCA"] - data["totalOfExpenses"]) /
                      data["totalCA"],
                    4
                  );
                } else {
                  return 0;
                }
              },
              format: "P2",
              customAttributes: {
                class: ["e-column-border-left-light", "font-weight-bolder"],
              },
              // customCss:"e-column-border-left",
            },
            {
              mode: "counted",
              visible:
                this.viewMode == "compare" || this.viewMode == "counted"
                  ? true
                  : false,
              type: "number",
              field: "marginCosting",
              label: "Marge brute",
              headerText: "Marge brute",
              width: 140,
              minWidth: 140,
              allowEdit: true,
              allowFiltering: true,
              allowSorting: true,
              showInColumnChooser: true,
              textAlign: "right",
              showFooterSum: true,
              defaultValue: 0,
              format: "C2",
              valueAccessor: function (field, data, column) {
                if (data["totalCA"] > 0) {
                  return formatNumber(
                    data["totalCA"] - data["totalOfExpenses"]
                  );
                } else {
                  return "--";
                }
              },
              customAttributes: {
                class: ["font-weight-bolder", "e-column-border-right-light"],
              },
              // customCss:"e-column-border-left",
            },
            {
              mode: "counted",
              visible:
                this.viewMode == "compare" || this.viewMode == "counted"
                  ? true
                  : false,
              type: "number",
              field: "totalRemaingToBill",
              label: "Reste à facturer HT",
              headerText: "Reste à facturer HT",
              width: 140,
              minWidth: 140,
              allowEdit: true,
              allowFiltering: true,
              allowSorting: true,
              showInColumnChooser: true,
              textAlign: "right",
              format: "C2",
              customAttributes: { class: "e-column-border-right-light" },
            },
          ],
        },
        {
          mode: "forecast",
          visible:
            this.viewMode == "compare" || this.viewMode == "forecast"
              ? true
              : false,
          headerText: "Prévu",
          customCss: "text-warning",
          customAttributes: {
            class: ["text-warning", "e-column-border-right-light"],
          },
          columns: [
            {
              mode: "forecast",
              visible:
                this.viewMode == "compare" || this.viewMode == "forecast"
                  ? true
                  : false,
              type: "number",
              field: "totalCAForecast",
              label: "CA prévu",
              headerText: "CA prévu",
              width: 140,
              minWidth: 140,
              allowEdit: true,
              allowFiltering: true,
              allowSorting: true,
              showInColumnChooser: true,
              textAlign: "right",
              format: "C2",
              customAttributes: {
                class: [
                  "bg-light-grey",
                  "text-warning",
                  "font-weight-bolder",
                  "e-column-border-right-light",
                ],
              },
            },
            {
              mode: "forecast",
              visible:
                this.viewMode == "compare" || this.viewMode == "forecast"
                  ? true
                  : false,
              type: "number",
              field: "totalOfExpensesForecast",
              label: "Total Charges prévues",
              headerText: "Charges prévues",
              width: 140,
              minWidth: 140,
              allowEdit: true,
              allowFiltering: true,
              allowSorting: true,
              showInColumnChooser: true,
              textAlign: "right",
              template: this.colorCurrencyTemplate,
              format: "C2",
              customAttributes: { class: "e-column-border-right-light" },
            },
          ],
        },
        {
          headerText: "Suivi mensuel",
          customCss: "text-primary",
          customAttributes: { class: "text-primary" },
          columns: columnsDetailMonths,
        },
      ];

      this.headerLoaded = true;
    },
    async actionBegin(args) {},
  },
};
</script>
<style>
#overviewgrid-dashboard_toolbarItems {
  padding-top: 42px !important;
}
</style>
