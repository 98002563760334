<template lang="pug">
  div#dashboard
      .d-flex.flex-column
          v-style(v-for="(analytic, analyticIndex) of analyticsSettingsList.filter(el=>el.color && el.color.length>2)" :key="analyticIndex")
            |.bg_{{analytic.color.substring(1)}}{
            |    background-color: {{analytic.color.substring(1).length>6 ? "#"+analytic.color.substring(1).substr(0, 6)+"35" : "#"+analytic.color.substring(1)+"35"}} !important;
            |}
          .content-header.d-flex.justify-content-between.align-items-center.p-1
              h2.content-header-title.m-0.pr-1.mr-1.d-flex
                  span.material-icons-outlined.cursor-pointer(@click="setHomePage()" :class="currentHomePage ? 'text-primary' : ''" style="color: #ccc;") home
                  span.d-block.d-md-none Analytique
                  span.d-none.d-md-block Tableau de bord > Analytique
              .content-header-search-filter.mr-1.d-none.d-md-block(style="flex: 1;")
                  .position-relative.w-100.d-flex.align-items-center
                      .d-none.d-lg-block.mr-1.flex
                          DateRanger(@setDates="setDates" userFilter="dashboardInvoiceFilters" :dateRangeDefault="dateRangeDefault" placeholder="Filtrer par période")
                      .d-none.d-lg-block.content-header-actions.mr-1
                          .e-btn-group.e-lib.e-css(role='group')
                              input#viewDay(type='radio' name='viewSlot' value="day" v-model="viewSlot")
                              label.e-btn.e-outline(for='viewDay') Jour
                              input#viewMonth(type='radio' name='viewSlot' value="month" v-model="viewSlot")
                              label.e-btn.e-outline.border-primary(for='viewMonth') Mois
                      ejs-multiselect(cssClass="d-none d-xl-block" id='affairAffairs' name="Affaires" :placeholder="haveLeaseModule ? 'Filtrer par affaire / bail' : 'Filtrer par affaire'" popupHeight='300px' sortOrder="Ascending" :allowFiltering='true' :dataSource="affairsList" filterType='Contains' :closePopupOnSelect="false" :fields="{ text: 'displayLabel', value: 'id' }" v-model='filterAffairs')
              .content-header-actions.d-flex
                  .e-btn-group.e-lib.e-css(role='group')
                      input#viewCounted(type='radio' name='viewMode' value="counted" v-model="viewMode")
                      label.e-btn.e-outline(for='viewCounted') Réél
                      input#viewCompare(type='radio' name='viewMode' value="compare" v-model="viewMode" v-if="checkModule(6)")
                      label.e-btn.e-outline.border-primary(for='viewCompare' v-if="checkModule(6)") Réél vs Prévu
                      input#viewForecast(type='radio' name='viewMode' value="forecast" v-model="viewMode" v-if="checkModule(6)")
                      label.e-btn.e-outline(for='viewForecast' v-if="checkModule(6)") Prévu
                  ejs-dropdownbutton( cssClass="ml-1 e-outline d-none d-lg-block" :select="selectParamerterAction" :items="parametersActionList" iconCss="e-drpdwnbtn-icons e-btn-sb-icons e-icon-field-settings"  :isPrimary="true" )
          b-col(cols='12').d-none.d-md-block.d-xl-none
              .d-flex.w-100.justify-content-between
                  DateRanger(@setDates="setDates" userFilter="dashboardInvoiceFilters" :dateRangeDefault="dateRangeDefault" placeholder="Filtrer par période")
                  ejs-multiselect(cssClass="ml-1" id='affairAffairs'  name="Affaires" :placeholder="haveLeaseModule ? 'Filtrer par affaire / bail' : 'Filtrer par affaire'" popupHeight='300px' sortOrder="Ascending" :allowFiltering='true' :dataSource="affairsList" filterType='Contains' :closePopupOnSelect="false" :fields="{ text: 'displayLabel', value: 'id' }" v-model='filterAffairs')
          b-col(cols='12').d-md-none
              .w-100.mb-50
                  DateRanger(@setDates="setDates" :dateRangeDefault="dateRangeDefault" userFilter="dashboardInvoiceFilters" placeholder="Filtrer par période")
              ejs-multiselect(id='affairAffairs' cssClass='w-100' name="Affaires" :placeholder="haveLeaseModule ? 'Filtrer par affaire / bail' : 'Filtrer par affaire'" popupHeight='300px' sortOrder="Ascending" :allowFiltering='true' :dataSource="affairsList" filterType='Contains' :closePopupOnSelect="false" :fields="{ text: 'displayLabel', value: 'id' }" v-model='filterAffairs')
      .w-100
          b-row.m-0.control-section.dashboard-default
              b-col(md="12" style='padding-top: 0px !important;')
                  .dashboard-default(v-if="dashboardLoaded")
                      ejs-dashboardlayout#defaultLayout(ref="DashbordInstance" :key="keyRefresh" :cellSpacing='spacing' :cellAspectRatio='aspectRatio' :columns='12' :allowFloating="true" :allowResizing="editDashboard" :allowDragging="editDashboard"  :mediaQuery="'max-width:991px'" @resizeStop="onChangeResizeStop" @resizeStart="onChangeResizeStart" @created="onCreatedDashboard" @change="onChangeResizeStop")
                          .e-panel(v-for="(panel, indexPanel) of panels" :key="indexPanel" :class="[editDashboard ? 'active' : '', panel.id=='invoices-sent' ? 'card-list-stats-dashboard bg-light-grey p-0' : panel.id=='quotes-sent' || panel.id=='quotes-signed' ? 'card-list-stats-dashboard bg-white border-turquoise p-0' : panel.id=='order-form-customers-sent' ? 'card-list-stats-dashboard bg-white border-primary p-0' : panel.id=='payments' ? 'card-list-stats-dashboard bg-white border-success p-0' : panel.id=='remainin-to-pay' ? 'card-list-stats-dashboard bg-white border-danger p-0' : '']" :id="panel.id" :data-row='panel.row' :data-col='panel.col' :data-sizeX='panel.sizeX' :data-sizeY='panel.sizeY' :data-minSizeX='panel.minSizeX' :data-minSizeY='panel.minSizeY')
                              .e-panel-container.p-1(v-if="panel.id=='invoices-sent'")
                                  span.material-icons.ml-25.btn-close(v-show="editDashboard" @click="onCloseIconHandler(panel.id)") close
                                  .d-flex.justify-content-center.h-100.w-100
                                      div.d-flex.flex-column.align-items-center.h-100.w-100
                                          h4.d-flex.align-items-center.justify-content-center.w-100.mb-0
                                              | Chiffre d'affaires
                                          div.d-flex.align-items-center(style="flex:1")
                                              .h0.text-primary(v-if="!isLoadingCreditsListStats && !isLoadingInvoicesListStats") {{ getSumInvoicesSentFormated }}
                                              div.number(v-else)
                                                  b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                  span(class="sr-only") Loading...
                                                  b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                  span(class="sr-only") Loading...
                                                  b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                  span(class="sr-only") Loading...
                                          ejs-tooltip.card-sum.d-flex.justify-content-center.align-items-center(v-if="!isLoadingCreditsListStats && !isLoadingCreditsListLastYearStats && !isLoadingInvoicesListStats && !isLoadingInvoicesListLastYearStats" :cssClass="'d-flex align-items-center'" ref="tooltip" :content="'CA HT de la même période à N-1 : ' + getSumInvoicesLastYearFormated")
                                              span CA N-1
                                              span.material-icons.text-success(style="font-size: 1.6rem;" :class="percentEvolutionInvoices>=0 ? 'text-success' : 'text-danger'") {{ percentEvolutionInvoices>=0 ? 'arrow_drop_up' : 'arrow_drop_down' }}
                                              span {{ percentEvolutionInvoices }} %
                                          h4.d-flex.align-items-center.justify-content-center.w-100.mb-0.mt-50(v-if="getSumRemainingToBill>0")
                                              | Reste à facturer
                                          div.d-flex.align-items-center.h-100(v-if="isLoadingOrderFormCustomersRemainingToBill && getSumRemainingToBill>0" style="flex:1; min-height: 50px;")
                                              div.number
                                                  b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                  span(class="sr-only") Loading...
                                                  b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                  span(class="sr-only") Loading...
                                                  b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                  span(class="sr-only") Loading...
                                          div.d-flex.align-items-center(v-else-if="getSumRemainingToBill>0" style="flex:1")
                                              .h1.font-weight-normal.text-primary {{ getSumRemainingToBillFormated }}
                              .e-panel-container.p-1(v-if="panel.id=='quotes-sent'")
                                  span.material-icons.ml-25.btn-close(v-show="editDashboard" @click="onCloseIconHandler(panel.id)") close
                                  .d-flex.justify-content-between.h-100.w-100
                                      div.d-flex.flex-column.align-items-start.h-100.w-100
                                          h4.d-flex.align-items-center.justify-content-between.w-100.mb-0
                                              | Devis validés
                                              div.bg-turquoise.text-white.text-center(v-if="!isLoadingQuotesListStats" style="border-radius:14px; min-width:24px; padding: 0px 5px;") {{ getCountQuotesSent }}
                                          div.d-flex.align-items-center.h-100(v-if="isLoadingQuotesListStats" style="flex:1; min-height: 50px;")
                                              div.number
                                                  b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                  span(class="sr-only") Loading...
                                                  b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                  span(class="sr-only") Loading...
                                                  b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                  span(class="sr-only") Loading...
                                          div.d-flex.align-items-center(v-else style="flex:1")
                                              .h1.font-weight-normal.text-dark {{ getSumQuotesSent }}
                                                  //- sup.font-weight-light(style="font-size: 60%") HT
                              .e-panel-container.p-1(v-if="panel.id=='quotes-signed'")
                                  span.material-icons.ml-25.btn-close(v-show="editDashboard" @click="onCloseIconHandler(panel.id)") close
                                  .d-flex.justify-content-between.h-100.w-100
                                      div.d-flex.flex-column.align-items-start.h-100.w-100
                                          h4.d-flex.align-items-center.justify-content-between.w-100.mb-0
                                              | Devis signés
                                              div.bg-turquoise.text-white.text-center(v-if="!isLoadingQuotesListStats" style="border-radius:14px; min-width:24px; padding: 0px 5px;") {{ getCountQuotesSigned  }}
                                          div.d-flex.align-items-center.h-100(v-if="isLoadingQuotesListStats" style="flex:1; min-height: 50px;")
                                              div.number
                                                  b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                  span(class="sr-only") Loading...
                                                  b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                  span(class="sr-only") Loading...
                                                  b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                  span(class="sr-only") Loading...
                                          div.d-flex.align-items-center(v-else style="flex:1")
                                              .h1.font-weight-normal.text-dark {{ getSumQuotesSigned }}
                                                  //- sup.font-weight-light(style="font-size: 60%") HT
                              .e-panel-container.p-1(v-if="panel.id=='order-form-customers-sent'")
                                  span.material-icons.ml-25.btn-close(v-show="editDashboard" @click="onCloseIconHandler(panel.id)") close
                                  .d-flex.justify-content-between.h-100.w-100
                                      div.d-flex.flex-column.align-items-start.h-100.w-100
                                          h4.d-flex.align-items-center.justify-content-between.w-100.mb-0
                                              | Commandes client
                                              div.bg-primary.text-white.text-center(v-if="!isLoadingOrderFormCustomersListStats" style="border-radius:14px; min-width:24px; padding: 0px 5px;") {{ getCountOrderFormsCustomersSent  }}
                                          div.d-flex.align-items-center.h-100(v-if="isLoadingOrderFormCustomersListStats" style="flex:1; min-height: 50px;")
                                              div.number
                                                  b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                  span(class="sr-only") Loading...
                                                  b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                  span(class="sr-only") Loading...
                                                  b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                  span(class="sr-only") Loading...
                                          div.d-flex.align-items-center(v-else style="flex:1")
                                              .h1.font-weight-normal.text-dark {{ getSumOrderFormsCustomersSent }}
                                                  //- sup.font-weight-light(style="font-size: 60%") HT
                              .e-panel-container.p-1(v-if="panel.id=='payments'")
                                  span.material-icons.ml-25.btn-close(v-show="editDashboard" @click="onCloseIconHandler(panel.id)") close
                                  .d-flex.justify-content-between.h-100.w-100
                                      div.d-flex.flex-column.align-items-start.h-100.w-100
                                          h4.d-flex.align-items-center.justify-content-between.w-100.mb-0
                                              | Encaissements
                                              div.bg-success.text-white.text-center(v-if="!isLoadingPaymentsList" style="border-radius:14px; min-width:24px; padding: 0px 5px;") {{ getCountPayments  }}
                                          div.d-flex.align-items-center.h-100(v-if="isLoadingPaymentsList" style="flex:1; min-height: 50px;")
                                              div.number
                                                  b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                  span(class="sr-only") Loading...
                                                  b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                  span(class="sr-only") Loading...
                                                  b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                  span(class="sr-only") Loading...
                                          div.d-flex.align-items-center(v-else style="flex:1")
                                              .h1.font-weight-normal.text-dark {{ getSumPayments }}
                                                  sup.font-weight-light(style="font-size: 60%") TTC
                              .e-panel-container.p-1(v-if="panel.id=='remainin-to-pay'")
                                  span.material-icons.ml-25.btn-close(v-show="editDashboard" @click="onCloseIconHandler(panel.id)") close
                                  .d-flex.justify-content-between.h-100.w-100
                                      div.d-flex.flex-column.align-items-start.h-100.w-100
                                          h4.d-flex.align-items-center.justify-content-between.w-100.mb-0
                                              .d-flex.align-items-center
                                                | Encours client
                                                ejs-tooltip.pl-1( content="Toutes les sommes restantes dues sur les factures jusqu\'à la date du jour sont incluses dans votre encours.")
                                                  feather-icon(icon="InfoIcon")
                                              div.bg-danger.text-white.text-center(v-if="!isLoadingInvoicesRemainingToPay" style="border-radius:14px; min-width:24px; padding: 0px 5px;") {{ getCountRemainingToPay  }}
                                          div.d-flex.align-items-center.h-100(v-if="isLoadingInvoicesRemainingToPay" style="flex:1; min-height: 50px;")
                                              div.number
                                                  b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                  span(class="sr-only") Loading...
                                                  b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                  span(class="sr-only") Loading...
                                                  b-spinner(class="mr-1" type="grow" variant="primary" small)
                                                  span(class="sr-only") Loading...
                                          div.d-flex.align-items-center(v-else style="flex:1")
                                              .h1.font-weight-normal.text-dark {{ getSumRemainingToPayFormated }}
                                                  sup.font-weight-light(style="font-size: 60%") TTC
                              .e-panel-container.p-1(v-else-if="panel.id=='global-chart-billing'")
                                  h4.d-none.d-lg-block.mb-0.position-absolute Statistiques par période
                                  h4.d-block.d-lg-none.mb-1.position-relative Statistiques par période
                                  span.material-icons.ml-25.btn-close(v-show="editDashboard" @click="onCloseIconHandler(panel.id)") close
                                  div.text-center.flex-center.h-100(v-if="!dataLoaded")
                                      b-spinner(variant="primary" style="width: 3rem; height: 3rem;")
                                  globalChartBilling(v-else-if="dataLoaded && panel.loaded" :viewType="viewType" :viewMode="viewMode" :data="dataGlobal")
                              .e-panel-container.p-1(v-if="panel.id=='tree-map-affairs'")
                                  .d-flex.flex-column.justify-content-between.h-100.w-100
                                      div.d-flex.w-100
                                          span.material-icons.ml-25.btn-close(v-show="editDashboard" @click="onCloseIconHandler(panel.id)") close
                                          h4.d-flex.w-100.mb-50  {{ haveLeaseModule ? 'Répartition par affaire / bail' : 'Répartition par affaire'  }}
                                      div.text-center.flex-center.h-100(v-if="isLoadingInvoicesListStats || isLoadingCreditsListStats" style="flex: 1;")
                                          b-spinner(variant="primary" style="width: 3rem; height: 3rem;")
                                      treeMapAffairs(v-else-if="!isLoadingInvoicesListStats && !isLoadingCreditsListStats && panel.loaded" :name="panel.id" :viewType="viewType" :viewMode="viewMode" :data="dataInvoicesAffairsFiltered" style="flex: 1;")
                              .e-panel-container.p-1(v-if="panel.id=='chart-ranking-companies'")
                                  .d-flex.flex-column.justify-content-between.h-100.w-100
                                      div.d-flex.w-100
                                          span.material-icons.ml-25.btn-close(v-show="editDashboard" @click="onCloseIconHandler(panel.id)") close
                                          h4.d-flex.w-100.mb-50 Classement par client
                                      div.text-center.flex-center.h-100(v-if="!dataLoaded" style="flex: 1;")
                                          b-spinner(variant="primary" style="width: 3rem; height: 3rem;")
                                      chartRankingCompanies(v-else-if="dataLoaded && panel.loaded" :name="panel.id" :viewType="viewType" :viewMode="viewMode" :data="dataCompaniesFiltered" style="flex: 1;")
                              .e-panel-container.p-0(v-if="panel.id=='global-grid-forecast'")
                                  .d-flex.flex-column.justify-content-between.h-100.w-100
                                      h4.p-1.mb-0.position-absolute(style="z-index:2; padding-top: 10px !important;") {{ haveLeaseModule ? 'Suivi par affaire / bail' : 'Suivi par affaire'  }}
                                      span.p-1.material-icons.ml-25.btn-close(v-show="editDashboard" @click="onCloseIconHandler(panel.id)") close
                                      div.text-center.flex-center.h-100(v-if="!dataLoaded" style="flex: 1;")
                                          b-spinner(variant="primary" style="width: 3rem; height: 3rem;")
                                      globalGridForecast(v-else-if="dataLoaded && panel.loaded" :name="panel.id" :viewType="viewType" :viewMode="viewMode" :viewSlot="viewSlot" :data="dataAffairsCostingFiltered" :dateRange="dateRange" style="flex: 1;")
  </template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  DashboardLayoutComponent,
  PanelDirective,
  PanelsDirective,
} from "@syncfusion/ej2-vue-layouts";

import {
  formatNumber,
  formatCurrency,
  replaceNbsps,
} from "@/types/api-orisis/library/FormatOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import DateRanger from "@/components/global/date-ranger/DateRanger.vue";

import GlobalChartBilling from "@/components/dashboard/billing/GlobalChartBilling";
import TreeMapAffairs from "@/components/dashboard/billing/TreeMapAffairs";
import ChartRankingCompanies from "@/components/dashboard/billing/ChartRankingCompanies";
import GlobalGridForecast from "@/components/dashboard/billing/GlobalGridForecast";

import { checkFunctionUser } from "@/auth/utils.ts";
export default {
  components: {
    "ejs-dashboardlayout": DashboardLayoutComponent,
    "e-panel": PanelDirective,
    "e-panels": PanelsDirective,
    DateRanger,
    GlobalChartBilling,
    TreeMapAffairs,
    ChartRankingCompanies,
    GlobalGridForecast,
  },
  data() {
    return {
      currentHomePage: false,
      title: "Tableau de bord de gestion",
      name: "dashboardInvoice",
      tabActive: "invoices",
      status: 0,
      tagsSelected: [],
      selectedRows: [],
      dateRangeDefault: [
        new Date(dayjs().startOf("month").format("YYYY-MM-DD")),
        new Date(dayjs().endOf("month").format("YYYY-MM-DD")),
      ],
      dateRange: {
        startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
        endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
      },
      filterAffairs: [],
      tvaMode: "HT",

      dataLoaded: false,
      statusList: [],
      keyRefresh: 0,

      viewMode: "counted",
      viewType: "amount",
      viewSlot: "month",

      dashboardLoaded: false,
      editDashboard: false,
      aspectRatio: 100 / 80,
      spacing: [10, 10],
      panelsDefault: [
        {
          id: "invoices-sent",
          row: 0,
          col: 0,
          sizeX: 3,
          sizeY: 2,
        },
        {
          id: "quotes-sent",
          row: 2,
          col: 0,
          sizeX: 3,
          sizeY: 1,
        },
        {
          id: "quotes-signed",
          row: 3,
          col: 0,
          sizeX: 3,
          sizeY: 1,
        },
        {
          id: "order-form-customers-sent",
          row: 4,
          col: 0,
          sizeX: 3,
          sizeY: 1,
        },
        {
          id: "payments",
          row: 5,
          col: 0,
          sizeX: 3,
          sizeY: 1,
        },
        {
          id: "remainin-to-pay",
          row: 6,
          col: 0,
          sizeX: 3,
          sizeY: 1,
        },
        {
          id: "global-chart-billing",
          row: 0,
          col: 3,
          sizeX: 9,
          sizeY: 7,
        },
        {
          id: "tree-map-affairs",
          row: 7,
          col: 0,
          sizeX: 6,
          sizeY: 4,
        },
        {
          id: "chart-ranking-companies",
          row: 7,
          col: 6,
          sizeX: 6,
          sizeY: 4,
        },
        {
          id: "global-grid-forecast",
          row: 13,
          col: 0,
          sizeX: 12,
          sizeY: 6,
        },
      ],
      panels: [],
      parametersActionList: [
        {
          text: "Activer l'édition du tableau de bord",
          iconCss: "e-btn-sb-icons e-icons e-frame-custom",
          type: "enableEditDashboard",
        },
        {
          text: "Réinitiliser mon tableau de bord",
          iconCss: "e-btn-sb-icons e-icons e-reset",
          type: "dashboardAnalytique",
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "affairsList",
      "affairCostingsList",
      "analyticsSettingsList",
      "quotesListStats",
      "isLoadingQuotesListStats",
      // "quotesListSignedStats",
      "orderFormCustomersListStats",
      "isLoadingOrderFormCustomersListStats",
      "orderFormCustomersListAllStats",
      "invoicesListStats",
      "isLoadingInvoicesListStats",
      "invoicesListLastYearStats",
      "creditsListStats",
      "isLoadingCreditsListStats",
      "isLoadingInvoicesListLastYearStats",
      "isLoadingCreditsListLastYearStats",
      "creditsListLastYearStats",
      "paymentsList",
      "isLoadingPaymentsList",
      "invoicesRemainingToPay",
      "isLoadingInvoicesRemainingToPay",
      "orderFormCustomersRemainingToBill",
      "isLoadingOrderFormCustomersRemainingToBill",
      "workspaceSelected",
      "userParametersList",
    ]),
    haveLeaseModule() {
      return this.checkFunctionUser(37, "all");
    },
    dataQuotesFiltered() {
      if (this.filterAffairs.length > 0) {
        return this.quotesListStats.datas.filter((quote) =>
          this.filterAffairs.includes(quote.affairId)
        );
      } else {
        return this.quotesListStats.datas;
      }
    },
    dataQuotesSignedFiltered() {
      if (this.filterAffairs.length > 0) {
        return this.quotesListStats.datas.filter((quote) => {
          return (
            this.filterAffairs.includes(quote.affairId) &&
            quote.signedDate &&
            new Date(quote.signedDate) >= this.dateRange.startDate &&
            new Date(quote.signedDate) <= this.dateRange.endDate
          );
        });
      } else {
        return this.quotesListStats.datas.filter((quote) => {
          return (
            quote.signedDate &&
            new Date(quote.signedDate) >= this.dateRange.startDate &&
            new Date(quote.signedDate) <= this.dateRange.endDate
          );
        });
      }
    },
    dataInvoicesFiltered() {
      let concatArray = this.invoicesListStats.datas.concat(
        this.creditsListStats.datas
      );
      if (this.filterAffairs.length > 0) {
        return concatArray.filter((invoice) =>
          this.filterAffairs.includes(invoice.affairId)
        );
      } else {
        return concatArray; //.filter(invoice=> invoice.status > 1 &&  invoice.status < 8 && invoice.status !== 5 )
      }
    },
    dataInvoicesLastYearFiltered() {
      let concatArray =
        this.invoicesListLastYearStats.datas &&
        this.invoicesListLastYearStats.datas.length > 0
          ? this.invoicesListLastYearStats.datas.concat(
              this.creditsListLastYearStats.datas
            )
          : this.creditsListLastYearStats.datas;
      if (this.filterAffairs.length > 0) {
        return concatArray.filter((invoice) =>
          this.filterAffairs.includes(invoice.affairId)
        );
      } else {
        return concatArray; //.filter(invoice=> invoice.status > 1 &&  invoice.status < 8 && invoice.status !== 5 )
      }
    },
    dataOrderFormsCustomersFiltered() {
      if (this.filterAffairs.length > 0) {
        return this.orderFormCustomersListStats.datas.filter(
          (orderFormCustomer) =>
            this.filterAffairs.includes(orderFormCustomer.affairId)
        );
      } else {
        return this.orderFormCustomersListStats.datas;
      }
    },
    dataPaymentsFiltered() {
      if (this.filterAffairs.length > 0) {
        return this.paymentsList.filter(
          (payment) =>
            payment.paymentType == 1 &&
            payment.invoices.some((invoice) =>
              this.filterAffairs.includes(invoice.affairId)
            )
        );
      } else {
        return this.paymentsList.filter((el) => el.paymentType == 1);
      }
    },
    dataOrderFormCustomersListAll() {
      if (this.filterAffairs.length > 0) {
        return this.orderFormCustomersListAllStats.datas.filter(
          (orderFormCustomer) =>
            this.filterAffairs.includes(orderFormCustomer.affairId)
        );
      } else {
        return this.orderFormCustomersListAllStats.datas;
      }
    },

    dataGlobal() {
      let dataGlobalMapped = [];

      let dateDebut = new Date(this.dateRange.startDate);
      let dateFin = new Date(this.dateRange.endDate);
      let dateActuelle = new Date(dateDebut);

      while (dateActuelle <= dateFin) {
        let dateStr = dayjs(dateActuelle).startOf("month").format("MMM YY");

        let totalQuotesSent = 0;
        let amountQuotesSent = 0;
        this.dataQuotesFiltered
          .filter((quote) =>
            this.viewSlot == "month"
              ? dayjs(dateActuelle).format("YYYY-MM") ==
                dayjs(quote.date).format("YYYY-MM")
              : dayjs(dateActuelle).format("YYYY-MM-DD") ==
                dayjs(quote.date).format("YYYY-MM-DD")
          )
          .forEach((quotes) => {
            totalQuotesSent++;
            amountQuotesSent += quotes.total;
          });

        let totalQuotesSigned = 0;
        let amountQuotesSigned = 0;
        this.dataQuotesSignedFiltered
          .filter((quote) =>
            this.viewSlot == "month"
              ? dayjs(dateActuelle).format("YYYY-MM") ==
                dayjs(quote.date).format("YYYY-MM")
              : dayjs(dateActuelle).format("YYYY-MM-DD") ==
                dayjs(quote.date).format("YYYY-MM-DD")
          )
          .forEach((quotes) => {
            totalQuotesSigned++;
            amountQuotesSigned += quotes.total;
          });

        let amountOrderFormsCustomersSent = 0;
        let totalOrderFormsCustomersSent = 0;
        this.dataOrderFormsCustomersFiltered
          .filter((orderFormCustomer) =>
            this.viewSlot == "month"
              ? dayjs(dateActuelle).format("YYYY-MM") ==
                dayjs(orderFormCustomer.date).format("YYYY-MM")
              : dayjs(dateActuelle).format("YYYY-MM-DD") ==
                dayjs(orderFormCustomer.date).format("YYYY-MM-DD")
          )
          .forEach((orderFormCustomers) => {
            totalOrderFormsCustomersSent++;
            amountOrderFormsCustomersSent += orderFormCustomers.total;
          });

        let amountInvoicesSent = 0;
        let totalInvoicesSent = 0;
        this.dataInvoicesFiltered
          .filter((invoice) =>
            this.viewSlot == "month"
              ? dayjs(dateActuelle).format("YYYY-MM") ==
                dayjs(invoice.date).format("YYYY-MM")
              : dayjs(dateActuelle).format("YYYY-MM-DD") ==
                dayjs(invoice.date).format("YYYY-MM-DD")
          )
          .forEach((invoices) => {
            totalInvoicesSent++;
            if (invoices.nature == "invoices") {
              amountInvoicesSent += invoices.total;
            } else {
              amountInvoicesSent -= invoices.total;
            }
          });

        let amountPayments = 0;
        let totalPayments = 0;
        this.dataPaymentsFiltered
          .filter((payment) =>
            this.viewSlot == "month"
              ? dayjs(dateActuelle).format("YYYY-MM") ==
                dayjs(payment.date).format("YYYY-MM")
              : dayjs(dateActuelle).format("YYYY-MM-DD") ==
                dayjs(payment.date).format("YYYY-MM-DD")
          )
          .forEach((payments) => {
            totalPayments++;
            amountPayments += payments.amount;
          });

        dataGlobalMapped.push({
          date:
            this.viewSlot == "month"
              ? dayjs(dateActuelle).format("YYYY-MM")
              : dayjs(dateActuelle).format("YYYY-MM-DD"),
          dateStr: dateStr.toUpperCase(),

          colorQuotesSent: "#6A7EC3",
          totalQuotesSent: totalQuotesSent,
          totalQuotesSentDisplayText:
            totalQuotesSent > 1
              ? totalQuotesSent + " devis validés"
              : totalQuotesSent + " devis validé",
          amountQuotesSent: amountQuotesSent.toFixed(2),
          amountQuotesSentDisplayText: this.replaceNbsps(
            this.formatCurrency(amountQuotesSent.toFixed(2))
          ),

          colorQuotesSigned: "#6A7EC3",
          totalQuotesSigned: totalQuotesSigned,
          totalQuotesSignedDisplayText:
            totalQuotesSigned > 1
              ? totalQuotesSigned + " devis signés"
              : totalQuotesSigned + " devis signés",
          amountQuotesSigned: amountQuotesSigned.toFixed(2),
          amountQuotesSignedDisplayText: this.replaceNbsps(
            this.formatCurrency(amountQuotesSigned.toFixed(2))
          ),

          colorOrderFormsCustomersSent: "#6A7EC3",
          totalOrderFormsCustomersSent: totalOrderFormsCustomersSent,
          totalOrderFormsCustomersSentDisplayText:
            totalOrderFormsCustomersSent > 1
              ? totalOrderFormsCustomersSent + " commandes validées"
              : totalInvoicesSent + " commande validée",
          amountOrderFormsCustomersSent:
            amountOrderFormsCustomersSent.toFixed(2),
          amountOrderFormsCustomersSentDisplayText: this.replaceNbsps(
            this.formatCurrency(amountOrderFormsCustomersSent.toFixed(2))
          ),

          colorInvoicesSent: "#6A7EC3",
          totalInvoicesSent: totalInvoicesSent,
          totalInvoicesSentDisplayText:
            totalInvoicesSent > 1
              ? totalInvoicesSent + " factures émises"
              : totalInvoicesSent + " facture émise",
          amountInvoicesSent: amountInvoicesSent.toFixed(2),
          amountInvoicesSentDisplayText: this.replaceNbsps(
            this.formatCurrency(amountInvoicesSent.toFixed(2))
          ),

          colorPayments: "#6A7EC3",
          totalPayments: totalPayments,
          totalPaymentsDisplayText:
            totalPayments > 1
              ? totalPayments + " encaissements"
              : totalPayments + " encaissement",
          amountPayments: amountPayments.toFixed(2),
          amountPaymentsDisplayText: this.replaceNbsps(
            this.formatCurrency(amountPayments.toFixed(2))
          ),
        });

        if (this.viewSlot == "month") {
          if (dateActuelle.getMonth() === 11) {
            dateActuelle.setFullYear(dateActuelle.getFullYear() + 1);
            dateActuelle.setMonth(0);
          } else {
            dateActuelle.setMonth(dateActuelle.getMonth() + 1);
          }
        } else {
          dateActuelle.setDate(dateActuelle.getDate() + 1);
        }
      }
      return dataGlobalMapped;
    },

    dataInvoicesAffairsFiltered() {
      let dataInvoicesAffairsMapped = [];
      if (this.filterAffairs.length > 0) {
        dataInvoicesAffairsMapped = this.dataInvoicesFiltered.filter(
          (invoice) =>
            invoice.affairId !== null &&
            this.filterAffairs.includes(invoice.affairId)
        );
      } else {
        dataInvoicesAffairsMapped = this.dataInvoicesFiltered.filter(
          (invoice) => invoice.affairId !== null
        );
      }
      dataInvoicesAffairsMapped = dataInvoicesAffairsMapped.reduce(
        (acc, obj) => {
          // On crée un object par affaire
          let findIndex = acc.findIndex((el) => el.affairId == obj.affairId);
          let totalCA = 0;
          let totalCATtc = 0;
          let totalCAForecast = 0;
          let totalOfExpenses = 0;
          let totalOfExpensesForecast = 0;
          let avct = 0;

          if (findIndex < 0) {
            // On instancie
            totalCA = obj.nature == "credits" ? -obj["total"] : obj["total"];
            totalCATtc =
              obj.nature == "credits" ? -obj["totalTtc"] : obj["totalTtc"];
            totalCAForecast =
              obj.nature == "credits" ? -obj["total"] : obj["total"];
            avct =
              totalCA > 0 && totalCAForecast > 0
                ? ((totalCA / totalCAForecast) * 100).toFixed(2)
                : 0;
            // On génère les colonnes des mois
            let dateDebut = new Date(this.dateRange.startDate);
            let dateFin = new Date(this.dateRange.endDate);
            let dateActuelle = new Date(dateDebut);
            let monthInvoices = {};
            while (dateActuelle <= dateFin) {
              let dateStr = dayjs(dateActuelle)
                .startOf("month")
                .format("MM_YYYY");
              monthInvoices[dateStr + "_totalCATtc"] = 0;

              if (
                dayjs(obj.date).startOf("month").format("MM_YYYY") == dateStr
              ) {
                monthInvoices[dateStr + "_totalCATtc"] =
                  obj["nature"] == "credits"
                    ? -obj["totalTtc"]
                    : obj["totalTtc"];
              }

              if (dateActuelle.getMonth() === 11) {
                dateActuelle.setFullYear(dateActuelle.getFullYear() + 1);
                dateActuelle.setMonth(0);
              } else {
                dateActuelle.setMonth(dateActuelle.getMonth() + 1);
              }
            }

            acc.push({
              ...obj,
              affairLabel: obj.affairName,
              companyLabel: obj.companyName,
              totalCA: totalCA,
              totalCATtc: totalCATtc,
              totalCAForecast: totalCAForecast,
              totalOfExpenses: totalOfExpenses,
              totalOfExpensesForecast: totalOfExpensesForecast,
              displayText_totalCA: this.replaceNbsps(
                this.formatCurrency(totalCA)
              ),
              displayText_totalCAForecast: this.replaceNbsps(
                this.formatCurrency(totalCAForecast)
              ),
              displayText_totalOfExpenses: this.replaceNbsps(
                this.formatCurrency(totalOfExpenses)
              ),
              displayText_totalOfExpensesForecast: this.replaceNbsps(
                this.formatCurrency(totalOfExpensesForecast)
              ),
              avct: avct,
              monthInvoices: monthInvoices,
            });
          } else {
            // On additionne
            acc[findIndex]["totalCA"] =
              obj.nature == "credits"
                ? acc[findIndex]["totalCA"] - obj["total"]
                : acc[findIndex]["totalCA"] + obj["total"];
            acc[findIndex]["totalCATtc"] =
              obj.nature == "credits"
                ? acc[findIndex]["totalCATtc"] - obj["totalTtc"]
                : acc[findIndex]["totalCATtc"] + obj["totalTtc"];
            acc[findIndex]["totalCAForecast"] =
              obj.nature == "credits"
                ? acc[findIndex]["totalCAForecast"] - obj["total"]
                : acc[findIndex]["totalCAForecast"] + obj["total"];
            acc[findIndex]["displayText_totalCA"] = this.replaceNbsps(
              this.formatCurrency(acc[findIndex]["totalCA"])
            );
            acc[findIndex]["displayText_totalCAForecast"] = this.replaceNbsps(
              this.formatCurrency(acc[findIndex]["totalCAForecast"])
            );
            acc[findIndex]["displayText_totalOfExpenses"] = this.replaceNbsps(
              this.formatCurrency(acc[findIndex]["totalOfExpenses"])
            );
            acc[findIndex]["displayText_totalOfExpensesForecast"] =
              this.replaceNbsps(
                this.formatCurrency(acc[findIndex]["totalOfExpensesForecast"])
              );
            // On génère les colonnes des mois
            let dateDebut = new Date(this.dateRange.startDate);
            let dateFin = new Date(this.dateRange.endDate);
            let dateActuelle = new Date(dateDebut);
            while (dateActuelle <= dateFin) {
              let dateStr = dayjs(dateActuelle)
                .startOf("month")
                .format("MM_YYYY");
              if (
                dayjs(obj.date).startOf("month").format("MM_YYYY") == dateStr
              ) {
                acc[findIndex]["monthInvoices"][dateStr + "_totalCATtc"] =
                  acc[findIndex]["monthInvoices"][dateStr + "_totalCATtc"] +
                  (obj["nature"] == "credits"
                    ? -obj["totalTtc"]
                    : obj["totalTtc"]);
              }

              if (dateActuelle.getMonth() === 11) {
                dateActuelle.setFullYear(dateActuelle.getFullYear() + 1);
                dateActuelle.setMonth(0);
              } else {
                dateActuelle.setMonth(dateActuelle.getMonth() + 1);
              }
            }

            acc[findIndex]["avct"] =
              acc[findIndex]["totalCA"] > 0 &&
              acc[findIndex]["totalCAForecast"] > 0
                ? (
                    (acc[findIndex]["totalCA"] /
                      acc[findIndex]["totalCAForecast"]) *
                    100
                  ).toFixed(2)
                : 0;
          }
          return acc;
        },
        []
      );

      return dataInvoicesAffairsMapped
        .filter((affair) => affair.affairId !== null)
        .sort(function (a, b) {
          return a.totalCA - b.totalCA;
        })
        .slice(-20);
    },
    dataPaymentsFilteredWithAffairId() {
      return this.dataPaymentsFiltered
        .flatMap((data) =>
          data.invoices.map((invoice) => ({
            ...invoice,
            date: data.date, // Ajoute la date correspondante de dataPaymentsFiltered
          }))
        ) // Fusionne les tableaux d'invoices en un seul tableau
        .reduce((acc, invoice) => {
          const { affairId, value } = invoice;
          if (affairId && affairId !== 0) {
            // Ajout de la condition pour exclure les factures avec affairId à 0 ou null
            if (!acc[affairId]) {
              acc[affairId] = {
                total: 0,
                invoices: [],
                monthPayments: {},
              };
            }
            acc[affairId].total =
              Math.round((acc[affairId].total + value) * 100) / 100;
            acc[affairId].invoices.push(invoice);

            let dateStr = dayjs(invoice.date)
              .startOf("month")
              .format("MM_YYYY");
            if (!acc[affairId].monthPayments[dateStr + "_totalPayments"]) {
              acc[affairId].monthPayments[dateStr + "_totalPayments"] = 0;
            }
            acc[affairId].monthPayments[dateStr + "_totalPayments"] += value;
          }
          return acc;
        }, {});
    },
    dataAffairsCostingFiltered() {
      let dataAffairsCostingsMapped = [];
      if (this.filterAffairs.length > 0) {
        dataAffairsCostingsMapped = this.affairCostingsList.filter((affair) =>
          this.filterAffairs.includes(affair.id)
        );
      } else {
        dataAffairsCostingsMapped = this.affairCostingsList;
      }
      dataAffairsCostingsMapped = dataAffairsCostingsMapped.reduce(
        (acc, obj) => {
          // On crée un object par affaire
          let findIndex = acc.findIndex((el) => el.affairId == obj.affairId);
          let totalCA = 0;
          let totalOfExpenses = 0;
          let totalCAForecast = 0;
          let totalOfExpensesForecast = 0;
          let avct = 0;
          if (findIndex < 0) {
            // On instancie
            if (obj.directionType == 1 && obj.sourceType == 1) {
              //entrée rééelle
              totalCA = obj["amountUnit"] * obj["valuatedQuantity"];
            } else if (obj.directionType == 1 && obj.sourceType == 2) {
              //entrée prévu
              totalCAForecast = obj["amountUnit"] * obj["valuatedQuantity"];
            } else if (obj.directionType == 2 && obj.sourceType == 1) {
              //sortie rééelle
              if (obj.documentApplicationType != 4) {
                totalOfExpenses = obj["amountUnit"] * obj["valuatedQuantity"];
              } else {
                totalCA = obj["amountUnit"] * -obj["valuatedQuantity"];
              }
            } else if (obj.directionType == 2 && obj.sourceType == 2) {
              //sortie prévu
              totalOfExpensesForecast =
                obj["amountUnit"] * obj["valuatedQuantity"];
            }
            avct =
              totalCA > 0 && totalCAForecast > 0
                ? ((totalCA / totalCAForecast) * 100).toFixed(2)
                : 0;
            // On génère les colonnes des codes analytiques
            let expensesByAnalytalCodes = {};
            this.analyticsSettingsList.forEach((analytic) => {
              if (
                obj.analyticsSettingId == analytic.id &&
                obj.directionType == 2 &&
                obj.sourceType == 1
              ) {
                expensesByAnalytalCodes[
                  "expenseByAnalytalCodes_" + analytic.id
                ] = obj["amountUnit"] * obj["valuatedQuantity"];
              } else {
                expensesByAnalytalCodes[
                  "expenseByAnalytalCodes_" + analytic.id
                ] = 0;
              }
            });

            // On génère les colonnes des mois
            let dateDebut = new Date(this.dateRange.startDate);
            let dateFin = new Date(this.dateRange.endDate);
            let dateActuelle = new Date(dateDebut);
            let monthsCostings = {};
            while (dateActuelle <= dateFin) {
              let dateStr = dayjs(dateActuelle)
                .startOf("month")
                .format("MM_YYYY");
              monthsCostings[dateStr + "_totalCA"] = 0;
              monthsCostings[dateStr + "_totalOfExpenses"] = 0;

              if (
                dayjs(obj.date).startOf("month").format("MM_YYYY") == dateStr
              ) {
                if (obj.directionType == 1 && obj.sourceType == 1) {
                  monthsCostings[dateStr + "_totalCA"] =
                    obj["amountUnit"] * obj["valuatedQuantity"];
                } else if (obj.directionType == 2 && obj.sourceType == 1) {
                  if (obj.documentApplicationType != 4) {
                    monthsCostings[dateStr + "_totalOfExpenses"] =
                      obj["amountUnit"] * obj["valuatedQuantity"];
                  } else {
                    monthsCostings[dateStr + "_totalCA"] =
                      obj["amountUnit"] * -obj["valuatedQuantity"];
                  }
                }
              }

              if (dateActuelle.getMonth() === 11) {
                dateActuelle.setFullYear(dateActuelle.getFullYear() + 1);
                dateActuelle.setMonth(0);
              } else {
                dateActuelle.setMonth(dateActuelle.getMonth() + 1);
              }
            }
            let totalRemaingToBill = 0;
            this.dataOrderFormCustomersListAll
              .filter(
                (orderFormCustomer) =>
                  orderFormCustomer.affairId == obj.affairId
              )
              .forEach((obj) => {
                totalRemaingToBill += obj["remainingToBillHt"];
              });

            // On ajoute l'affaire
            acc.push({
              affairId: obj.affairId,
              affairLabel: this.affairsList.find(
                (affair) => affair.id == obj.affairId
              )
                ? this.affairsList.find((affair) => affair.id == obj.affairId)
                    .displayLabel
                : obj.affairId,
              totalCA: totalCA,
              totalOfExpenses: totalOfExpenses,
              totalCAForecast: totalCAForecast,
              totalOfExpensesForecast: totalOfExpensesForecast,
              totalRemaingToBill: totalRemaingToBill,
              avct: avct,
              ...expensesByAnalytalCodes,
              ...monthsCostings,
            });
          } else {
            // On additionne
            if (obj.directionType == 1 && obj.sourceType == 1) {
              //entrée rééelle
              acc[findIndex]["totalCA"] +=
                obj["amountUnit"] * obj["valuatedQuantity"];
            } else if (obj.directionType == 1 && obj.sourceType == 2) {
              //entrée prévu
              acc[findIndex]["totalCAForecast"] +=
                obj["amountUnit"] * obj["valuatedQuantity"];
            } else if (obj.directionType == 2 && obj.sourceType == 1) {
              //sortie rééelle
              if (obj.documentApplicationType != 4) {
                acc[findIndex]["totalOfExpenses"] +=
                  obj["amountUnit"] * obj["valuatedQuantity"];
              } else {
                acc[findIndex]["totalCA"] +=
                  obj["amountUnit"] * -obj["valuatedQuantity"];
              }
            } else if (obj.directionType == 2 && obj.sourceType == 2) {
              //sortie prévu
              acc[findIndex]["totalOfExpensesForecast"] +=
                obj["amountUnit"] * obj["valuatedQuantity"];
            }

            this.analyticsSettingsList.forEach((analytic) => {
              if (
                obj.analyticsSettingId == analytic.id &&
                obj.directionType == 2 &&
                obj.sourceType == 1
              ) {
                acc[findIndex]["expenseByAnalytalCodes_" + analytic.id] +=
                  obj["amountUnit"] * obj["valuatedQuantity"];
              }
            });

            // On génère les colonnes des mois
            let dateDebut = new Date(this.dateRange.startDate);
            let dateFin = new Date(this.dateRange.endDate);
            let dateActuelle = new Date(dateDebut);
            while (dateActuelle <= dateFin) {
              let dateStr = dayjs(dateActuelle)
                .startOf("month")
                .format("MM_YYYY");
              if (
                dayjs(obj.date).startOf("month").format("MM_YYYY") == dateStr
              ) {
                if (obj.directionType == 1 && obj.sourceType == 1) {
                  acc[findIndex][dateStr + "_totalCA"] +=
                    obj["amountUnit"] * obj["valuatedQuantity"];
                } else if (obj.directionType == 2 && obj.sourceType == 1) {
                  if (obj.documentApplicationType != 4) {
                    acc[findIndex][dateStr + "_totalOfExpenses"] +=
                      obj["amountUnit"] * obj["valuatedQuantity"];
                  } else {
                    acc[findIndex][dateStr + "_totalCA"] +=
                      obj["amountUnit"] * -obj["valuatedQuantity"];
                  }
                }
              }

              if (dateActuelle.getMonth() === 11) {
                dateActuelle.setFullYear(dateActuelle.getFullYear() + 1);
                dateActuelle.setMonth(0);
              } else {
                dateActuelle.setMonth(dateActuelle.getMonth() + 1);
              }
            }

            acc[findIndex]["avct"] =
              acc[findIndex]["totalCA"] > 0 &&
              acc[findIndex]["totalCAForecast"] > 0
                ? (
                    (acc[findIndex]["totalCA"] /
                      acc[findIndex]["totalCAForecast"]) *
                    100
                  ).toFixed(2)
                : 0;
          }
          return acc;
        },
        []
      );

      return dataAffairsCostingsMapped
        .map((affair) => {
          const payments =
            this.dataPaymentsFilteredWithAffairId[affair.affairId];
          const invoices = this.dataInvoicesAffairsFiltered.find(
            (invoice) => invoice.affairId == affair.affairId
          );
          return {
            ...affair,
            totalPayments: payments ? payments.total : 0,
            totalCATtc: invoices ? invoices.totalCATtc : 0,
            ...(payments ? payments.monthPayments : {}),
            ...(invoices ? invoices.monthInvoices : {}),
          };
        })
        .filter(
          (affair) =>
            affair.affairId !== null &&
            (affair.totalCA > 0 || affair.totalOfExpenses > 0)
        );
    },

    dataCompaniesFiltered() {
      let dataCompaniesMapped = [];
      dataCompaniesMapped = this.dataInvoicesFiltered.reduce((acc, obj) => {
        // On crée un object par client
        let findIndex = acc.findIndex((el) => el.companyId == obj.companyId);
        if (findIndex < 0) {
          // On instancie
          acc.push({
            ...obj,
            companyId: obj.companyId,
            companyLabel: obj.companyName
              ? obj.companyName
              : "Client non identifiée",
            totalCA: obj.nature == "invoices" ? obj.total : -obj.total,
            countInvoicePeriod: obj.nature == "invoices" ? 1 : 0,
            countCreditPeriod: obj.nature == "invoices" ? 0 : 1,
            displayText: obj.nature == "invoices" ? "1 facture" : "1 avoir",
          });
        } else {
          if (obj.nature == "invoices") {
            acc[findIndex]["totalCA"] += obj.total;
            acc[findIndex]["countInvoicePeriod"]++;
          } else {
            acc[findIndex]["totalCA"] -= obj.total;
            acc[findIndex]["countCreditPeriod"]++;
          }
          if (
            acc[findIndex]["countInvoicePeriod"] >= 1 &&
            acc[findIndex]["countCreditPeriod"] >= 1
          ) {
            acc[findIndex]["displayText"] =
              acc[findIndex]["countInvoicePeriod"] +
              " " +
              (acc[findIndex]["countInvoicePeriod"] > 1
                ? "factures / "
                : "facture / ") +
              acc[findIndex]["countCreditPeriod"] +
              " " +
              (acc[findIndex]["countCreditPeriod"] > 1 ? "avoirs" : "avoir");
          } else if (acc[findIndex]["countInvoicePeriod"] >= 1) {
            acc[findIndex]["displayText"] =
              acc[findIndex]["countInvoicePeriod"] +
              " " +
              (acc[findIndex]["countInvoicePeriod"] > 1
                ? "factures"
                : "facture");
          } else if (acc[findIndex]["countCreditPeriod"] >= 1) {
            acc[findIndex]["displayText"] =
              acc[findIndex]["countCreditPeriod"] +
              " " +
              (acc[findIndex]["countCreditPeriod"] > 1 ? "avoirs" : "avoir");
          }
        }
        return acc;
      }, []);

      return dataCompaniesMapped
        .sort(function (a, b) {
          return a.totalCA - b.totalCA;
        })
        .slice(-10);
    },

    getCountQuotesSent() {
      return this.dataQuotesFiltered ? this.dataQuotesFiltered.length : 0;
    },
    getSumQuotesSent() {
      let sum = 0;
      this.dataQuotesFiltered.forEach((obj) => {
        sum += obj["total"];
      });
      return this.formatCurrency(sum);
    },

    getCountQuotesSigned() {
      return this.dataQuotesSignedFiltered
        ? this.dataQuotesSignedFiltered.length
        : 0;
    },
    getSumQuotesSigned() {
      let sum = 0;
      this.dataQuotesSignedFiltered.forEach((obj) => {
        sum += obj["total"];
      });
      return this.formatCurrency(sum);
    },

    getCountOrderFormsCustomersSent() {
      return this.dataOrderFormsCustomersFiltered
        ? this.dataOrderFormsCustomersFiltered.length
        : 0;
    },
    getSumOrderFormsCustomersSent() {
      let sum = 0;
      this.dataOrderFormsCustomersFiltered.forEach((obj) => {
        sum += obj["total"];
      });
      return this.formatCurrency(sum);
    },
    getSumInvoicesSent() {
      let sum = 0;
      this.dataInvoicesFiltered.forEach((obj) => {
        if (obj.nature == "invoices") {
          sum += obj["total"];
        } else {
          sum -= obj["total"];
        }
      });
      return sum;
    },
    getSumInvoicesSentFormated() {
      return this.formatCurrency(this.getSumInvoicesSent);
    },
    getSumInvoicesLastYear() {
      let sum = 0;
      this.dataInvoicesLastYearFiltered.forEach((obj) => {
        if (obj.nature == "invoices") {
          sum += obj["total"];
        } else {
          sum -= obj["total"];
        }
      });
      return sum;
    },
    getSumInvoicesLastYearFormated() {
      return this.formatCurrency(this.getSumInvoicesLastYear);
    },
    percentEvolutionInvoices() {
      if (this.getSumInvoicesSent > 0 && this.getSumInvoicesLastYear > 0) {
        return (
          (this.getSumInvoicesSent / this.getSumInvoicesLastYear - 1) *
          100
        ).toFixed(2);
      } else {
        return 100.0;
      }
    },

    getCountPayments() {
      return this.dataPaymentsFiltered ? this.dataPaymentsFiltered.length : 0;
    },
    getSumPayments() {
      let sum = 0;
      this.dataPaymentsFiltered.forEach((obj) => {
        sum += obj["amount"];
      });
      return this.formatCurrency(sum);
    },
    getCountRemainingToBill() {
      return this.orderFormCustomersRemainingToBill.count;
    },
    getSumRemainingToBill() {
      return this.orderFormCustomersRemainingToBill.amount;
    },
    getSumRemainingToBillFormated() {
      return this.formatCurrency(this.orderFormCustomersRemainingToBill.amount);
    },
    getCountRemainingToPay() {
      return this.invoicesRemainingToPay.count;
    },
    getSumRemainingToPayFormated() {
      return this.formatCurrency(this.invoicesRemainingToPay.amount);
    },
  },
  async created() {
    this.getAnalyticsSettings({});
    this.getAffairs({});
    this.getModuleParameters({});
    if (
      this.userParametersList.find((el) => el.key == "homePage") &&
      this.userParametersList.find((el) => el.key == "homePage").value ==
        this.$route.path
    ) {
      this.currentHomePage = true;
    } else {
      this.currentHomePage = false;
    }
    if (
      this.userParametersList.find((el) => el.key == "dashboardAnalytique") &&
      this.userParametersList.find((el) => el.key == "dashboardAnalytique") &&
      JSON.parse(
        this.userParametersList.find((el) => el.key == "dashboardAnalytique")
          .value
      ) &&
      JSON.parse(
        this.userParametersList.find((el) => el.key == "dashboardAnalytique")
          .value
      ).panels
    ) {
      this.panels = JSON.parse(
        this.userParametersList.find((el) => el.key == "dashboardAnalytique")
          .value
      ).panels;
    } else {
      this.panels = JSON.parse(JSON.stringify(this.panelsDefault));
    }
  },
  async activated() {
    this.resizeHandler();
    window.addEventListener("resize", this.resizeHandler);
    this.keyRefresh++;
    this.dashboardLoaded = true;
    this.initDashboard();
  },
  methods: {
    ...mapActions([
      "createOrUpdateUserParameter",
      "getAnalyticsSettings",
      "getAffairs",
      "getAffairCostings",
      "getQuotesStats",
      "getOrderFormCustomersStats",
      "getInvoicesStats",
      "getCreditsStats",
      "getPayments",
      "getInvoiceRemainingToPay",
      "getOrderFormCustomerRemainingToBill",
      "getModuleParameters",
    ]),
    formatNumber,
    formatCurrency,
    replaceNbsps,
    checkFunctionUser,
    setHomePage() {
      this.createOrUpdateUserParameter({
        label: "Page d'accueil",
        key: "homePage",
        value: this.$route.path,
      });
      this.currentHomePage = true;
    },
    checkModule(moduleId) {
      return true;
      // return this.$store.getters.workspaceSelected.modules.find(
      //   (el) => el.id == moduleId
      // );
    },
    resizeHandler(e) {
      let newAspectRatio = 0;

      if (window.innerWidth >= 1500) {
        newAspectRatio = 1.45;
      } else if (window.innerWidth >= 1350) {
        newAspectRatio = 1.3;
      } else if (window.innerWidth >= 1200) {
        newAspectRatio = 1;
      } else if (window.innerWidth >= 992) {
        newAspectRatio = 0.8;
      } else if (window.innerWidth >= 850) {
        newAspectRatio = 8;
      } else if (window.innerWidth >= 768) {
        newAspectRatio = 7;
      } else if (window.innerWidth >= 576) {
        newAspectRatio = 7;
      } else if (window.innerWidth < 576) {
        newAspectRatio = 5;
      }

      if (this.aspectRatio !== newAspectRatio) {
        this.aspectRatio = newAspectRatio;
        this.$forceUpdate();
      }
    },
    async changeTab(tab) {
      this.tabActive = tab;
      this.tagsSelected = [];
      this.$forceUpdate();
    },
    setTagsSelected(val) {
      this.tagsSelected = val;
    },
    setStatusList(val) {
      this.statusList = val;
    },
    async initDashboard() {
      let filter = this.userParametersList.find(
        (el) => el.key == "dashboardInvoiceFilters"
      );
      if (filter && JSON.parse(filter.value).dateRange) {
        this.setDates(JSON.parse(filter.value).dateRange);
      } else {
        this.setDates({
          startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
          endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
        });
      }
    },
    async setDates(dates) {
      if (dates.startDate) {
        this.dataLoaded = false;
        this.dateRange = {
          startDate: new Date(dayjs(dates.startDate).format("YYYY-MM-DD")),
          endDate: dates.endDate
            ? new Date(dayjs(dates.endDate).format("YYYY-MM-DD"))
            : new Date(dayjs().format("YYYY-MM-DD")),
        };
        this.dateRangeDefault = [
          this.dateRange.startDate,
          this.dateRange.endDate,
        ];

        let allPromises = [
          this.getAffairCostings({
            startDate: this.dateRange.startDate,
            endDate: this.dateRange.endDate,
            sourceType: null,
          }),
          this.getQuotesStats({
            startDate: this.dateRange.startDate,
            endDate: this.dateRange.endDate,
          }),
          this.getOrderFormCustomersStats({
            startDate: this.dateRange.startDate,
            endDate: this.dateRange.endDate,
          }),
          this.getInvoicesStats({
            dateFrom: this.dateRange.startDate,
            dateTo: this.dateRange.endDate,
          }),
          this.getCreditsStats({
            dateFrom: this.dateRange.startDate,
            dateTo: this.dateRange.endDate,
          }),
          this.getOrderFormCustomersStats({
            startDate: "2000-01-01",
            endDate: this.dateRange.endDate,
            route: "all",
          }),
          // this.getQuotesStats({
          //   startDate: this.dateRange.startDate,
          //   endDate: this.dateRange.endDate,
          //   isSigned: true,
          // }),
        ];

        this.getPayments({
          startTo: this.dateRange.startDate,
          endTo: this.dateRange.endDate,
        });
        this.getInvoiceRemainingToPay();
        this.getOrderFormCustomerRemainingToBill({
          endTo: this.dateRange.endDate,
        });

        Promise.all(allPromises).then(() => {
          this.dataLoaded = true;
        });
      }
    },
    selectParamerterAction(args) {
      if (args.item.type == "enableEditDashboard") {
        this.editDashboard = true;
        this.parametersActionList.splice(
          this.parametersActionList.findIndex(
            (elem) => elem.type == "enableEditDashboard"
          ),
          1
        );
        this.parametersActionList.unshift({
          text: "Désactiver l'édition du tableau de bord",
          iconCss: "e-btn-sb-icons e-icons e-border-outer",
          type: "disableEditDashboard",
        });
      } else if (args.item.type == "disableEditDashboard") {
        this.editDashboard = false;
        this.parametersActionList.splice(
          this.parametersActionList.findIndex(
            (elem) => elem.type == "disableEditDashboard"
          ),
          1
        );
        this.parametersActionList.unshift({
          text: "Activer l'édition du tableau de bord",
          iconCss: "e-btn-sb-icons e-icons e-frame-custom",
          type: "enableEditDashboard",
        });
      } else if (args.item.type == "dashboardAnalytique") {
        this.dashboardLoaded = false;
        this.panels = JSON.parse(JSON.stringify(this.panelsDefault));
        this.createOrUpdateUserParameter({
          label: "Paramètres du tableau de bord analytique",
          key: "dashboardAnalytique",
          value: JSON.stringify({ panels: this.panels }),
        });
        this.$nextTick(() => {
          this.dashboardLoaded = true;
        });
        this.$forceUpdate();
      }
    },
    onChangeResizeStart() {
      this.updatePanelSize(false);
    },
    onChangeResizeStop() {
      this.updatePanelSize(true);
    },
    onCreatedDashboard(args) {
      this.updatePanelSize(true);
    },
    updatePanelSize(loaded = true) {
      var panelElement = document.querySelectorAll(".e-panel");
      for (var i = 0; i < panelElement.length; i++) {
        this.panels.find((el) => el.id == panelElement[i].id).loaded = false;
        this.panels.find((el) => el.id == panelElement[i].id).height =
          panelElement[i].offsetHeight;
        if (loaded) {
          this.panels.find((el) => el.id == panelElement[i].id).loaded = true;
        }
      }
      this.$forceUpdate();
      if (loaded) {
        this.createOrUpdateUserParameter({
          label: "Paramètres du tableau de bord analytique",
          key: "dashboardAnalytique",
          value: JSON.stringify({
            panels: this.$refs.DashbordInstance.serialize(),
          }),
        });
      }
    },
    onCloseIconHandler(panelId) {
      this.$refs.DashbordInstance.removePanel(panelId);
      this.$forceUpdate();
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.resizeHandler);
  },
};
</script>
<style lang="scss">
@import "~@syncfusion/ej2-base/styles/material.css";
@import "~@syncfusion/ej2-vue-layouts/styles/material.css";

.e-panel-container {
  .btn-close {
    display: none;
    position: absolute;
    cursor: pointer;
    right: 0px;
    z-index: 2;
  }
  &:hover {
    .btn-close {
      display: block;
      right: 1rem;
    }
  }
}
.e-dashboardlayout.e-control .cursor-default {
  cursor: default !important;
}
/* DashboardLayout element styles  */
// .dashboard-default #defaultLayout.e-dashboardlayout.e-control .e-panel:hover span {
//     display: block;
// }

.e-dashboardlayout.e-control .e-panel {
  border-radius: 5px;
}

.e-panel .e-gridheader {
  border-top: 0px !important;
}

.dashboard-default #defaultLayout .e-panel .e-panel-container .text-align {
  vertical-align: middle;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.dashboard-default .e-template-icon {
  padding: 10px;
  float: right;
  display: none;
}

.dashboard-default .e-clear-icon::before {
  content: "\e7a7";
  font-size: 12px;
  font-family: "e-icons";
}
.bootstrap5 .dashboard-default .e-clear-icon::before,
.bootstrap5-dark .dashboard-default .e-clear-icon::before {
  content: "\e7e7";
  font-size: 16px;
}

.dashboard-default .e-clear-icon {
  position: absolute;
  right: 0;
  cursor: pointer;
}

.dashboard-default #defaultLayout .e-panel {
  z-index: 2 !important;
  box-shadow: none !important;
  &:hover {
    border: 1px #e0e0e0 solid;
  }
  &.active {
    z-index: 1000 !important;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15) !important;
  }
  &.box-shadow {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.15) !important;
    cursor: pointer !important;
  }
  &.active:hover {
    border: 1px #bdbdbd solid;
  }
}

.dashboard-default #defaultLayout .e-panel .e-panel-container .text-align {
  vertical-align: middle;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.dashboard-default .e-template-icon {
  padding: 10px;
  float: right;
  display: none;
}

.dashboard-default .e-clear-icon::before {
  content: "\e7a7";
  font-size: 12px;
  font-family: "e-icons";
}

.bootstrap5 .dashboard-default .e-clear-icon::before,
.bootstrap5-dark .dashboard-default .e-clear-icon::before {
  content: "\e7e7";
  font-size: 16px;
}

.dashboard-default .text-align {
  line-height: 160px;
}

.dashboard-default .e-clear-icon {
  position: absolute;
  right: 0;
  cursor: pointer;
}
</style>
