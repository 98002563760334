<template lang="pug">
ejs-chart#rankingCompanies(:ref="name" v-if="dataLoaded" :key="key" style='display:block' theme='bootstrap5' align='center' :primaryXAxis='primaryXAxis' :primaryYAxis='primaryYAxis' :chartArea='chartArea' :width='width' :tooltip='tooltip' :legendSettings='legend')
    e-series-collection
        e-series(:dataSource='data' fill="#0c3571" type='Bar' xName='companyLabel' yName='totalCA' name='Chiffre d\'affaires' columnWidth='.5' tooltipMappingName='displayText' :marker='marker')   
</template>
<script>
import {
  ChartComponent,
  SeriesCollectionDirective,
  SeriesDirective,
  BarSeries,
  Category,
  Tooltip,
  Legend,
  DataLabel,
  Highlight,
} from "@syncfusion/ej2-vue-charts";

export default {
  components: {
    "ejs-chart": ChartComponent,
    "e-series-collection": SeriesCollectionDirective,
    "e-series": SeriesDirective,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    viewType: {
      type: String,
      default: "amount",
    },
    viewMode: {
      type: String,
      default: "compare",
    },
  },
  data: function () {
    return {
      title: "Classement par client",
      name: "rankingCompanies",
      dataLoaded: false,
      key: 0,

      //Initializing Primary X Axis
      primaryXAxis: {
        valueType: "Category",
        majorGridLines: { width: 0 },
      },

      //Initializing Primary Y Axis
      primaryYAxis: {
        labelFormat: "{value} €",
        edgeLabelPlacement: "Shift",
        majorTickLines: { width: 0 },
        lineStyle: { width: 0 },
      },
      chartArea: {
        border: {
          width: 0,
        },
      },

      marker: {
        dataLabel: {
          visible: false,
          position: "Top",
          font: {
            fontWeight: "600",
            color: "#ffffff",
          },
        },
      },
      width: "100%",

      tooltip: {
        enable: true,
        format: "${point.tooltip} : ${point.y}",
      },
      legend: {
        visible: false,
        enable: false,
      },
    };
  },
  watch: {
    data: function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataLoaded = false;
        this.$nextTick(() => {
          this.dataLoaded = true;
        });
      }
    },
  },
  async mounted() {
    this.dataLoaded = true;
  },
  provide: {
    chart: [BarSeries, Legend, Category, Tooltip, DataLabel, Highlight],
  },
};
</script>
